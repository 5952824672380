/*
 * Theme Name: sunzen
 *
 * Layout: Sidebar-Content
 *
 * Learn more: https://developer.wordpress.org/themes/basics/template-files/
*/

.content-area {
	float: right;
	margin: 0 0 0 -25%;
	width: 100%;
}
.site-main {
	margin: 0 0 0 25%;
}
.site-content .widget-area {
	float: left;
	overflow: hidden;
	width: 25%;
}
.site-footer {
	clear: both;
	width: 100%;
}

.no-sidebar .content-area {
	float: none;
	margin-left: auto;
	margin-right: auto;
}
.no-sidebar .site-main {
	margin-right: 0;
}
