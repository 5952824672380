/*
 * Theme Name: sunzen
 *
 * Layout: Content-Sidebar
 *
 * Learn more: https://developer.wordpress.org/themes/basics/template-files/
*/

.content-area {
	float: left;
	margin: 0 -25% 0 0;
	width: 100%;
}
.site-main {
	margin: 0 25% 0 0;
}
.site-content .widget-area {
	float: right;
	overflow: hidden;
	width: 25%;
}
.site-footer {
	clear: both;
	width: 100%;
}

.no-sidebar .content-area {
	float: none;
	margin-left: auto;
	margin-right: auto;
}
.no-sidebar .site-main {
	margin-right: 0;
}
